$sgbs-icons-font-path: 'fonts/' !default;
$sgbs-icons-font-family: 'Material Icons' !default;
$sgbs-icons-font-display: auto !default;

@font-face {
  font-family: $sgbs-icons-font-family;
  font-style: normal;
  font-weight: 400;
  font-display: $sgbs-icons-font-display;
  src: url('#{$sgbs-icons-font-path}MaterialIcons-Regular.ttf');
}

.icon {
  font-family: $sgbs-icons-font-family;
}
